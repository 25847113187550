import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import Header from './header';

export default function Clipping(){
    const navigate = useNavigate();
    const handleLink = (page:string) => {
        navigate(page)
    }
    return(
        <React.Fragment>
            <Box sx={{margin:'10px'}}>
                <Header />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/')}}>
                        TOPへ戻る
                    </Button>
                </Box>
                <hr />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/clipping_post')}}>
                        {`POST /clipping`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/clipping_put')}}>
                        {`PUT /clipping/<clipping_task_id>`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/clipping_get_detail')}}>
                        {`GET /clipping/detail/<clipping_task_id>`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/clipping_get_list')}}>
                        {`GET /clipping/list/<client_id>`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/clipping_delete')}}>
                        {`DELETE /clipping/<clipping_task_id>`}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    )
}
