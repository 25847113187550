import * as React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { Backdrop, CircularProgress, CssBaseline } from '@mui/material';
import { baseSettings, keyIO, SettingsContext, typeSettings } from './system/settings';

import Articles from './UI/articles_menu';
import Clipping from './UI/clipping_menu';
import Top from './UI/top';

import ClippingDelete from './UI/clipping/delete';
import ClippingGetDetail from './UI/clipping/get_detail';
import ClippingGetList from './UI/clipping/get_list';
import ClippingPost from './UI/clipping/post';
import ClippingPut from './UI/clipping/put';

import ArticlesDelete from './UI/articles/delete';
import ArticlesGetList from './UI/articles/get_list';
import ArticlesPost from './UI/articles/post';
import ArticlesPut from './UI/articles/put';

import ReloadDialog from './UI/dialog/reload';

type typeVersion = {
  enabled:boolean;
  version:string;
  buildTime:string;
}

function App() {
  const [settings,setSettings] = React.useState<typeSettings[]>(baseSettings);
  const [mode,setMode] = React.useState<string>('dev');
  const [authkey,setAuthkey] = React.useState<string>('');
  const [backdrop,setBackdrop] = React.useState<boolean>(false);
  const [version,setVersion] = React.useState<typeVersion>({enabled:false,version:'',buildTime:''});
  const [trigger,setTrigger] = React.useState<boolean>(false);
  const [open,setOpen] = React.useState<boolean>(false);
  React.useEffect(()=>{
    const tempKey:string = keyIO.get();
    setAuthkey(tempKey);
    const fetchVersion = async () => {
      try{
        const response = await fetch('/version.json');
        if (response.ok) {
          const data = await response.json();
          setVersion({enabled:true,version:data.version,buildTime:data.buildTime});
          setTrigger(true);
          // console.log('Version detection started.')
        }else{
          console.log('Version detection only be enabled on build application.')
        }
      }catch(err){
        console.log('Error',err);
      }
    }
    fetchVersion();
  },[]);
  React.useEffect(()=>{
    if(trigger && version.enabled){
      const versionDetection = async () => {
        try{
          const uniqueString = () => `${Date.now().toString(36)}${Math.random().toString(36).substring(2, 7)}`;
          const response = await fetch(`/version.json?uuid=${uniqueString}`);
          if (response.ok) {
            const data = await response.json();
            if((version.version === data.version) && (version.buildTime === data.buildTime)){
              // console.log('This is a latest version.')
            }else{
              console.log('This application is an old version.')
              setOpen(true)
            }
          }
        }catch(err){
          console.log('Error',err);
        }
      }
      // 定期実行
      const intervalId = setInterval(() => {
        versionDetection();
      }, 60000 * 1); // 60000ms = 1分

      // クリーンアップ関数でインターバルをクリア
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line
  },[trigger,version]);
  return (
    <SettingsContext.Provider value={{settings,setSettings,mode,setMode,authkey,setAuthkey,backdrop,setBackdrop}}>
      <CssBaseline />
      <URLDetection />
      <Backdrop sx={{color:'#fff',zIndex:9999}} open={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ReloadDialog open={open} setOpen={setOpen} />
    </SettingsContext.Provider>
  );
}

function URLDetection(){
  return(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={ <Top /> } />
        <Route path="/clipping_menu" element={ <Clipping /> } />
        <Route path="/clipping_post" element={ <ClippingPost /> } />
        <Route path="/clipping_put" element={ <ClippingPut /> } />
        <Route path="/clipping_delete" element={ <ClippingDelete /> } />
        <Route path="/clipping_get_detail" element={ <ClippingGetDetail /> } />
        <Route path="/clipping_get_list" element={ <ClippingGetList /> } />
        <Route path="/articles_menu" element={ <Articles /> } />
        <Route path="/articles_post" element={ <ArticlesPost /> } />
        <Route path="/articles_put" element={ <ArticlesPut /> } />
        <Route path="/articles_get_list" element={ <ArticlesGetList /> } />
        <Route path="/articles_delete" element={ <ArticlesDelete /> } />
      </Routes>
    </BrowserRouter>
  )
}
export default App;
