import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';

import { typeBaseData } from '../articles/post';

export default function DialogArticlesPost({target,open,setOpen,data,setData}:{
    target:string,
    open:boolean,
    setOpen:React.Dispatch<React.SetStateAction<boolean>>,
    data:typeBaseData,
    setData:React.Dispatch<React.SetStateAction<typeBaseData>>
}){
    const handleClose = () => {
        setOpen(false);
    };
    const handleEditValue = (e:any) => {
        let tempObj = {...data};
        const temp = e.target.value.trim();
        // console.log('tempObj',tempObj);
        if(target==='client_id'){
            // tempObj.client_id = temp;
        }else if(target==='title'){
            tempObj.title = temp;
        }else if(target==='url'){
            tempObj.url = temp;
        }else if(target==='news_date'){
            tempObj.news_date = temp;
        }else if(target==='media.code'){
            tempObj.media.code = temp;
        }else if(target==='media.name'){
            tempObj.media.name = temp;
        }else if(target==='media.url'){
            tempObj.media.url = temp;
        }
        // console.log('tempObj',tempObj);
        setData(tempObj);
    }
    const returnValue = ():string => {
        let str:string = ''
        if(target==='client_id'){
            // str = data.client_id;
        }else if(target==='title'){
            str = data.title;
        }else if(target==='url'){
            str = data.url;
        }else if(target==='news_date'){
            str = data.news_date;
        }else if(target==='media.code'){
            str = data.media.code;
        }else if(target==='media.name'){
            str = data.media.name;
        }else if(target==='media.url'){
            str = data.media.url;
        }
        return str;
    }
    const anyVaue:string = returnValue();
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{target}</DialogTitle>
                <DialogContent sx={{minWidth:'480px'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name={target}
                        label="value"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={anyVaue}
                        onChange={handleEditValue}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}