import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';
import { keyIO } from '../../system/settings';

export default function DialogKey({open,setOpen,authkey,setAuthkey}:{
    open:boolean,
    setOpen:React.Dispatch<React.SetStateAction<boolean>>,
    authkey:string,
    setAuthkey:React.Dispatch<React.SetStateAction<string>>
}){
    const handleClose = () => {
        setOpen(false);
    };
    // React.useEffect(()=>{
    //     console.log(`key=`,authkey)
    // },[authkey])
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={()=>{handleClose()}}
                PaperProps={{
                    component: 'form',
                    onSubmit: (e: React.FormEvent<HTMLFormElement>) => {
                        e.preventDefault();
                        const formData = new FormData(e.currentTarget);
                        const formJson = Object.fromEntries((formData as any).entries());
                        const newKey = formJson.key;
                        keyIO.set(newKey);
                        setAuthkey(newKey);
                        // console.log(newKey);
                        handleClose();
                    },
                }}
            >
                <DialogTitle>認証キーの設定</DialogTitle>
                <DialogContent sx={{minWidth:'480px'}}>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="key"
                        label="Key value"
                        type="text"
                        fullWidth
                        variant="standard"
                        defaultValue={authkey}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button type="submit">SET</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}