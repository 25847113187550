import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import * as React from 'react';

export default function ReloadDialog({open,setOpen}:{
    open:boolean,
    setOpen:React.Dispatch<React.SetStateAction<boolean>>
}) {
    const handleClose = () => {
        setOpen(false);
    };
    const handleReload = () => {
        window.location.reload();
    }
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"Version Detection"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        新しいバージョンのWEBアプリがあります。リロードしてください。
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                    <Button onClick={handleReload}>Reload App</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
