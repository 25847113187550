import { Button, Typography } from '@mui/material';
import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { SettingsContext } from '../system/settings';
import DialogKey from './dialog/input_key';

export default function Header(){
    const location = useLocation();
    const fixedPath = pathFix(location);
    const [open,setOpen] = React.useState<boolean>(false);
    const {settings,mode,authkey,setAuthkey} = React.useContext(SettingsContext);
    const setting = settings.filter((one)=>{
        return one.mode === mode
    })[0];
    return (
        <React.Fragment>
            <Typography variant="h4" component="h4">
                PRW API TESTER{(fixedPath!=='')?` (${fixedPath})`:''}
            </Typography>
            <hr />
                base URL : {setting.url}<br />
                key : {(authkey==='')?'未設定':authkey}&emsp;<Button variant="contained" size="small" onClick={()=>{setOpen(true)}}>認証キーの設定</Button>
            <hr />
            <DialogKey open={open} setOpen={setOpen} authkey={authkey} setAuthkey={setAuthkey} />
        </React.Fragment>
    )
}

function pathFix(location:any){
    let fixedPath = '';
    if(location.pathname.startsWith('/clipping')){
        fixedPath = 'clipping';
    }else if(location.pathname.startsWith('/articles')){
        fixedPath = 'articles';
    }
    return fixedPath;
}