import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { SettingsContext, anyIO } from '../../system/settings';
import DialogClippingPost from '../dialog/input_clipping_post';
import Header from '../header';

const name = 'clipping_post';

export type typeBaseData = {
    keywords:{
        and:string[];
        or:string[];
        not:string[];
    };
    startdate:string;
    enddate:string;
    release_id:string;
    client_id:string;
}
const baseData:typeBaseData = {
    keywords:{and:[],or:[],not:[]},
    startdate:'',
    enddate:'',
    release_id:'',
    client_id:''
};

export default function ClippingPost(){
    const navigate = useNavigate();
    const {settings,mode,authkey} = React.useContext(SettingsContext);
    const setting = settings.filter((one)=>{
        return one.mode === mode
    })[0];
    const [result,setResult] = React.useState<string>('');
    const [target,setTarget] = React.useState<string>('');
    const [open,setOpen] = React.useState<boolean>(false);
    const [data,setData] = React.useState<typeBaseData>(baseData);
    const handleLink = (page:string) => {
        navigate(page)
    }
    const handleRequest = async () => {
        setResult('requesting');
        const url = `${setting.url}/clipping`
        console.log('url',url);
        console.log('request body',data);
        console.log(`"Auth-Fuerte" header value is "${authkey}"`);
        try{
            const response = await fetch(url, {
                method:'POST',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Auth-Fuerte':authkey
                },
                body:JSON.stringify(data)
            });
            if (response.ok) {
                const responseData = await response.json(); // レスポンスをJSONとして解析
                console.log('response',responseData)
                if(responseData.err_code === 0){
                    setResult('success');
                }else if(responseData.err_code === 401){
                    setResult('auth error');
                }else{
                    setResult(`error code: ${responseData.err_code}`);
                }
            }else{
                const responseData = await response.json(); // レスポンスをJSONとして解析
                console.log('response(error)',responseData)
                // console.log('response(error)',response)
                setResult('http error')
                // throw new Error('ネットワークエラー');
            }
        }catch(error){
            console.log('エラー',error)
        }finally{

        }
    }
    const handleEdit = (input:string) => {
        setTarget(input);
        setOpen(true);
    }
    React.useEffect(()=>{
        const str = anyIO.get(name);
        setData((str==='')?baseData:JSON.parse(str))
    },[]);
    React.useEffect(()=>{
        const str = JSON.stringify(data);
        anyIO.set(name,str);
    },[data]);
    return(
        <React.Fragment>
            <Box sx={{margin:'10px'}}>
                <Header />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/')}}>
                        TOPへ戻る
                    </Button>
                    <Button sx={{textTransform:'none',marginLeft:'10px'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/clipping_menu')}}>
                        {`/clipping API 一覧へ戻る`}
                    </Button>
                </Box>
                <hr />
                <Box>
                    POST /clipping&emsp;
                    <Button sx={{textTransform:'none'}} variant="contained" size="large" onClick={handleRequest}>
                        リクエスト送信
                    </Button>&emsp;
                    <span style={{fontWeight:'bold',fontSize:'1.1em',color:'red'}}>{result}</span>
                    <hr />
                    &lt; Request body &gt;<br />
                    ※ 単純なキーワードで登録しないこと。大量の結果が取得されてしまうため。
                    <Box sx={{margin:'10px',backgroundColor:'#f5caa4',borderRadius:'10px',padding:'5px',fontSize:'1.1em',lineHeight:'2.0em'}}>
                    {'{'}<br />
                    &emsp;{'"keywords":{'}<br />
                    &emsp;&emsp;{'"and":['}{(data.keywords.and.length>0)?`"${data.keywords.and.join('","')}"`:''}{'],'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('keywords.and')}}>編集</Button><br />
                    &emsp;&emsp;{'"or":['}{(data.keywords.or.length>0)?`"${data.keywords.or.join('","')}"`:''}{'],'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('keywords.or')}}>編集</Button><br />
                    &emsp;&emsp;{'"not":['}{(data.keywords.not.length>0)?`"${data.keywords.not.join('","')}"`:''}{']'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('keywords.not')}}>編集</Button><br />
                    &emsp;{'}'}<br />
                    &emsp;{'"startdate":"'}{data.startdate}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('startdate')}}>編集</Button><br />
                    &emsp;{'"enddate":"'}{data.enddate}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('enddate')}}>編集</Button><br />
                    &emsp;{'"release_id":"'}{data.release_id}{'",'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('release_id')}}>編集</Button><br />
                    &emsp;{'"client_id":"'}{data.client_id}{'"'}&emsp;<Button variant="outlined" size="small" onClick={()=>{handleEdit('client_id')}}>編集</Button><br />
                    {'}'}
                    </Box>
                </Box>
            </Box>
            <DialogClippingPost target={target} open={open} setOpen={setOpen} data={data} setData={setData} />
        </React.Fragment>
    )
}
