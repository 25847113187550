import { Box, Button, TextField } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import { SettingsContext, anyIO } from '../../system/settings';
import Header from '../header';

const name = 'articles_get_list';

export default function ArticlesGetList(){
    const navigate = useNavigate();
    const {settings,mode,authkey} = React.useContext(SettingsContext);
    const setting = settings.filter((one)=>{
        return one.mode === mode
    })[0];
    const [result,setResult] = React.useState<string>('');
    const [clipping_task_id,set_clipping_task_id] = React.useState<string>('');
    const [limit,set_limit] = React.useState<string>('');
    const [offset,set_offset] = React.useState<string>('');
    const handleLink = (page:string) => {
        navigate(page)
    }
    const calcParams = () => {
        let params = '';
        if((limit !== '') || (offset !== '')){
            if(limit !== ''){
                if(params !== ''){ params += '&' }
                params += `limit=${limit}`;
            }
            if(limit !== ''){
                if(params !== ''){ params += '&' }
                params += `offset=${offset}`;
            }
            if(params !== ''){ params = `?${params}` }
        }
        return params;
    }
    const handleRequest = async () => {
        setResult('requesting');
        const url = `${setting.url}/articles/list/${clipping_task_id}` + calcParams();
        console.log('url',url);
        console.log(`"Auth-Fuerte" header value is "${authkey}"`);
        try{
            const response = await fetch(url, {
                method:'GET',
                mode:'cors',
                headers:{
                    'Content-Type':'application/json',
                    'Auth-Fuerte':authkey
                }
            });
            if (response.ok) {
                const responseData = await response.json(); // レスポンスをJSONとして解析
                console.log('response',responseData)
                if(responseData.err_code === 0){
                    setResult('success');
                }else if(responseData.err_code === 401){
                    setResult('auth error');
                }else{
                    setResult(`error code: ${responseData.err_code}`);
                }
            }else{
                const responseData = await response.json(); // レスポンスをJSONとして解析
                console.log('response(error)',responseData)
                // console.log('response(error)',response)
                setResult('http error')
                // throw new Error('ネットワークエラー');
            }
        }catch(error){
            console.log('エラー',error)
        }finally{

        }
    }
    const handleChangeLimit = (e:any) => {
        const temp = e.target.value.replace(/\D/g,"");
        const newValue = (temp==='')?'':`${Number(temp)}`
        set_limit(newValue)
    }
    const handleChangeOffset = (e:any) => {
        const temp = e.target.value.replace(/\D/g,"");
        const newValue = (temp==='')?'':`${Number(temp)}`
        set_offset(newValue)
    }
    React.useEffect(()=>{
        const temp_clipping_task_id = anyIO.get(`${name}_clipping_task_id`);
        const temp_limit = anyIO.get(`${name}_limit`);
        const temp_offset = anyIO.get(`${name}_offset`);
        set_clipping_task_id(temp_clipping_task_id);
        set_limit(temp_limit);
        set_offset(temp_offset);
    },[]);
    React.useEffect(()=>{
        anyIO.set(`${name}_clipping_task_id`,clipping_task_id);
        anyIO.set(`${name}_limit`,limit);
        anyIO.set(`${name}_offset`,offset);
    },[clipping_task_id,limit,offset]);
    return(
        <React.Fragment>
            <Box sx={{margin:'10px'}}>
                <Header />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/')}}>
                        TOPへ戻る
                    </Button>
                    <Button sx={{textTransform:'none',marginLeft:'10px'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/articles_menu')}}>
                        {`/articles API 一覧へ戻る`}
                    </Button>
                </Box>
                <hr />
                <Box>
                    GET /articles/list/{`<clipping_task_id>`}&emsp;
                    <Button sx={{textTransform:'none'}} variant="contained" size="large" onClick={handleRequest}>
                        リクエスト送信
                    </Button>&emsp;
                    <span style={{fontWeight:'bold',fontSize:'1.1em',color:'red'}}>{result}</span>
                    <hr />
                    <Box sx={{marginBottom:'20px',width:'50%'}}>
                        &lt; clipping_task_id &gt;
                        <TextField
                            fullWidth
                            value={clipping_task_id}
                            onChange={(e)=>{set_clipping_task_id(e.target.value)}}
                        />
                    </Box>
                    <Box sx={{marginBottom:'20px'}}>
                        <TextField sx={{width:'160px'}} label="limit" name="limit" value={limit} onChange={handleChangeLimit} />&nbsp;
                        <TextField sx={{width:'160px'}} label="offset" name="offset" value={offset} onChange={handleChangeOffset} />
                    </Box>
                </Box>
            </Box>
        </React.Fragment>
    )
}
