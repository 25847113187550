import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import * as React from 'react';

import { typeBaseData } from '../clipping/post';

export default function DialogClippingPost({target,open,setOpen,data,setData}:{
    target:string,
    open:boolean,
    setOpen:React.Dispatch<React.SetStateAction<boolean>>,
    data:typeBaseData,
    setData:React.Dispatch<React.SetStateAction<typeBaseData>>
}){
    const [value,setValue] = React.useState<string>('')
    const handleClose = () => {
        setValue('');
        setOpen(false);
    };
    const handleClear = () => {
        let tempObj = {...data};
        if(target==='keywords.and'){
            tempObj.keywords.and = [];
        }else if(target==='keywords.or'){
            tempObj.keywords.or = [];
        }else if(target==='keywords.not'){
            tempObj.keywords.not = [];
        }
        setData(tempObj);
    }
    const handleAdd = () => {
        let tempObj = {...data};
        if(target==='keywords.and' && value.trim() !== ''){
            tempObj.keywords.and.push(value.trim());
        }else if(target==='keywords.or' && value.trim() !== ''){
            tempObj.keywords.or.push(value.trim());
        }else if(target==='keywords.not' && value.trim() !== ''){
            tempObj.keywords.not.push(value.trim());
        }
        setData(tempObj);
        setValue('');
    }
    const handleEditValue = (e:any) => {
        let tempObj = {...data};
        const temp = e.target.value.trim();
        // console.log('tempObj',tempObj);
        if(target==='startdate'){
            tempObj.startdate = temp;
        }else if(target==='enddate'){
            tempObj.enddate = temp;
        }else if(target==='release_id'){
            tempObj.release_id = temp;
        }else if(target==='client_id'){
            tempObj.client_id = temp;
        }
        // console.log('tempObj',tempObj);
        setData(tempObj);
    }
    const returnValue = ():string => {
        let str:string = ''
        if(target==='startdate'){
            str = data.startdate;
        }else if(target==='enddate'){
            str = data.enddate;
        }else if(target==='release_id'){
            str = data.release_id;
        }else if(target==='client_id'){
            str = data.client_id;
        }
        return str;
    }
    const anyVaue:string = returnValue();
    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{target}</DialogTitle>
                {((target==='keywords.and')||(target==='keywords.or')||(target==='keywords.not')) &&
                    <React.Fragment>
                        <DialogContent sx={{minWidth:'480px'}}>
                            <TextField
                                autoFocus
                                margin="dense"
                                name={target}
                                label="value"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={value}
                                onChange={(e)=>{setValue(e.target.value)}}
                            />
                            {(target==='keywords.and') && <Box>[{(data.keywords.and.length>0)?`"${data.keywords.and.join('","')}"`:''}]</Box>}
                            {(target==='keywords.or') && <Box>[{(data.keywords.or.length>0)?`"${data.keywords.or.join('","')}"`:''}]</Box>}
                            {(target==='keywords.not') && <Box>[{(data.keywords.not.length>0)?`"${data.keywords.not.join('","')}"`:''}]</Box>}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                            <Button onClick={handleClear}>Clear</Button>
                            <Button onClick={handleAdd}>Add</Button>
                        </DialogActions>
                    </React.Fragment>
                }
                {((target==='startdate')||(target==='enddate')||(target==='release_id')||(target==='client_id')) &&
                    <React.Fragment>
                        <DialogContent sx={{minWidth:'480px'}}>
                            <TextField
                                autoFocus
                                margin="dense"
                                name={target}
                                label="value"
                                type="text"
                                fullWidth
                                variant="standard"
                                value={anyVaue}
                                onChange={handleEditValue}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </React.Fragment>
                }
            </Dialog>
        </React.Fragment>
    );
}