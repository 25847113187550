import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import Header from './header';

export default function Articles(){
    const navigate = useNavigate();
    const handleLink = (page:string) => {
        navigate(page)
    }
    return(
        <React.Fragment>
            <Box sx={{margin:'10px'}}>
                <Header />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="medium" color="error" onClick={()=>{handleLink('/')}}>
                        TOPへ戻る
                    </Button>
                </Box>
                <hr />
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/articles_post')}}>
                        {`POST /articles/<clipping_task_id>`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/articles_put')}}>
                        {`PUT /articles/<clipping_task_id>/<article_id>`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/articles_get_list')}}>
                        {`GET /articles/list/<clipping_task_id>`}
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/articles_delete')}}>
                        {`DELETE /articles/<clipping_task_id>/<article_id>`}
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    )
}
