import { Box, Button } from '@mui/material';
import * as React from 'react';
import { useNavigate } from "react-router-dom";

import Header from './header';

export default function Top(){
    const navigate = useNavigate();
    const handleLink = (page:string) => {
        navigate(page)
    }
    return(
        <React.Fragment>
            <Box sx={{margin:'10px'}}>
                <Header />
                <Box>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/clipping_menu')}}>
                        /clipping API
                    </Button>
                </Box>
                <Box sx={{marginTop:'10px'}}>
                    <Button sx={{textTransform:'none'}} variant="outlined" size="large" onClick={()=>{handleLink('/articles_menu')}}>
                        /articles API
                    </Button>
                </Box>
            </Box>
        </React.Fragment>
    )
}