import * as React from 'react';

export type typeSettings = {
    mode:string;
    title:string;
    url:string;
}

export const baseSettings:typeSettings[] = [
    {
        mode:'dev',
        title:'テスト環境',
        url:'https://dev.fuerteservices.com'
    },{
        mode:'main',
        title:'本番環境',
        url:'https://fuerteservices.com'
    }
];

export type typeContextSettings = {
    settings:typeSettings[],
    setSettings:React.Dispatch<React.SetStateAction<typeSettings[]>>,
    mode:string,
    setMode:React.Dispatch<React.SetStateAction<string>>,
    authkey:string,
    setAuthkey:React.Dispatch<React.SetStateAction<string>>,
    backdrop:boolean,
    setBackdrop:React.Dispatch<React.SetStateAction<boolean>>
}

//セッション共有
export const SettingsContext = React.createContext<typeContextSettings>(undefined as any);
export const useSettingsContext = () => React.useContext(SettingsContext);

const keyname = 'prw_api_tester_key';

export const keyIO = {
    get:()=>{
        let onLocal = window.localStorage.getItem(keyname);
        const key = onLocal?onLocal:'';
        return key;
    },
    set:(newValue:string)=>{
        window.localStorage.setItem(keyname,newValue);
    },
    remove:()=>{
        window.localStorage.removeItem(keyname);
    }
};

const prefix = 'prw_api_tester_';
export const anyIO = {
    get:(name:string)=>{
        let onLocal = window.localStorage.getItem(`${prefix}${name}`);
        const value = onLocal?onLocal:'';
        return value;
    },
    set:(name:string,newValue:string)=>{
        window.localStorage.setItem(`${prefix}${name}`,newValue);
    },
    remove:(name:string)=>{
        window.localStorage.removeItem(`${prefix}${name}`);
    }
};
